<style lang="less" scoped>
  @import "../../../assets/css/variables";
</style>

<template :is="view" transition="fade" transition-mode="out-in">
  <div class="page-wrapper">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/service/repair/list' }">
        运维服务
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/service/fault/list' }">
        故障等级管理
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/service/fault/info' }">
        {{ rootName }}的子故障
      </el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-container clearfix" v-on:keyup.13="queryFault">
      <el-col :span="24" style="padding-bottom: 0px;">
        <el-form :inline="true" :model="filters">
          <el-form-item>
            <el-input
              style="width: 240px;"
              v-model="filters.name"
              placeholder="请输入故障名称"
            ></el-input>
            <el-input v-show="false" style="width: 240px;"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="queryFault">查询</el-button>
          </el-form-item>
        </el-form>
      </el-col>
      <el-table
        :data="faultList"
        v-loading.body="listLoading"
        @selection-change="selsChange"
        class="list-table"
      >
        <el-table-column type="selection" width="35"></el-table-column>
        <el-table-column label="序号" align="center" width="70">
          <template slot-scope="scope">
            <span>{{ scope.$index + pager.size * (pager.page - 1) + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          min-width="180"
          label="故障名称"
          sortable
        ></el-table-column>
        <el-table-column
          prop="parentCategory.name"
          label="所属父级故障"
        ></el-table-column>
        <el-table-column prop="remarks" label="故障描述"></el-table-column>
        <!-- <el-table-column label="创建时间" width="180">
          <template slot-scope="scope">
            <span v-if="scope.row.createTime">{{moment(scope.row.createTime).format('YYYY-MM-DD HH:mm:ss')}}</span>
          </template>
        </el-table-column> -->
        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <el-button
              :disabled="!editPermission"
              size="small"
              @click="handleEdit(scope.$index, scope.row)"
            >
              修改
            </el-button>
            <el-button
              :disabled="!editPermission"
              type="danger"
              class="m-l-xs"
              size="small"
              @click="handleDel(scope.$index, scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--工具条-->
      <el-col :span="24" class="bottom-tool-container">
        <el-button
          :disabled="!editPermission"
          type="info"
          @click="faultVisible = true"
        >
          新增故障
        </el-button>
        <el-button
          :disabled="!(sels.length > 0 && editPermission)"
          type="danger"
          @click="batchRemove"
        >
          批量删除
        </el-button>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pager.page"
          :page-sizes="[PAGE_SIZE, PAGE_SIZE * 2, PAGE_SIZE * 3, 50]"
          :page-size="pager.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          background
          class="pull-right"
        ></el-pagination>
      </el-col>
    </div>
    <el-dialog
      :title="isEdit ? '修改故障' : '新增故障'"
      :close-on-click-modal="false"
      size="small"
      :visible.sync="faultVisible"
    >
      <el-form
        ref="faultForm"
        :rules="rules"
        :model="faultInfo"
        label-width="80px"
      >
        <el-form-item label="名称:" prop="name">
          <el-input
            v-model="faultInfo.name"
            placeholder="请输入故障名称"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="父级故障:" prop="parentCategory">
          <el-select v-model="faultInfo.parentCategory" filterable placeholder="请选择所属一级故障" @change="handleSelect">
            <el-option
              v-for="item in rootFaultList"
              :key="item"
              :label="item.name"
              :value="item">
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="描述:" prop="remarks">
          <el-input
            type="textarea"
            :rows="5"
            :maxlength="200"
            v-model="faultInfo.remarks"
            placeholder="请输入故障的描述"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="margin-top: -20px">
        <el-button @click="faultVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitFault">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import moment from "moment";
  import {
    getAllFaultCategoryByroot,
    getAllFaultCategoryByParentId,
    removefaultCategory,
    createFaultCategory,
    updateFaultCategory,
  } from "../../../services/orderCategory";
  import { Notification } from "element-ui";
  import { PAGE_SIZE } from "../../../config";
  import { mapGetters } from "vuex";

  export default {
    data() {
      const valiName = (rule, value, callback) => {
        if (value === "") {
          callback(new Error("请输入故障名称"));
        } else if (value.length > 30 || value.length < 2) {
          callback(new Error("故障名称只能由2-30位字符组成!"));
        } else {
          callback();
        }
      };
      const valiCategoryRoot = (rule, value, callback) => {
        if (!value) {
          return callback(new Error("必须选择一项"));
        } else {
          callback();
        }
      };

      return {
        editPermission: true,
        parentId: "",
        parentCategoryId: "",
        rootFaultList: [],
        moment,
        isEdit: false, //修改信息 false 新增 true 修改
        loaded: false, //第一次加载状态
        PAGE_SIZE,
        pager: {
          size: PAGE_SIZE,
          page: 1,
        },
        choosedRowIndex: -1, //修改、删除操作的时候当前选中的行的下标
        total: 1,
        faultList: [],
        filters: {
          name: "",
        },
        faultInfo: {
          name: "",
          remarks: "",
          isRoot: false,
          parentCategory: "",
        },
        defaultFaultInfo: {
          name: "",
          remarks: "",
          isRoot: false,
          parentCategory: "",
        },
        rules: {
          name: [{ validator: valiName, trigger: "blur" }],
          parentCategory: [{ validator: valiCategoryRoot, trigger: "change" }],
          remarks: [
            {
              require: false,
              min: 4,
              max: 200,
              message: "描述长度在4到200个字符",
              trigger: "change",
            },
          ],
        },
        sels: [],
        listLoading: false, //列表加载完毕状态
        faultVisible: false, //新增设备类型的view
      };
    },

    computed: {
      ...mapGetters({ user: "userInfo" }),
    },
    methods: {
      /**
       * 获取所有的一级故障
       */
      handleSelect(item) {
        // console.log(this.faultInfo.parentCategory);
        this.faultInfo["parentCategory.id"] = item.id;
        // this.faultInfo['parentCategory.name'] = item.name;
      },

      /**
       * 判断编辑权限
       */
      checkEditPermission() {
        let permissions = this.user && this.user.permissions;
        this.editPermission = permissions.some((item) => {
          return item === "OrderCategory:update";
        });
      },

      async queryFaultRoot() {
        this.listLoading = true;
        const responseData = await getAllFaultCategoryByroot();
        if (responseData.errorCode === 0) {
          this.rootFaultList =
            (responseData && responseData.data && responseData.data.content) ||
            [];
        }
      },

      /**
       * 提交故障信息
       */
      async submitFault() {
        this.$refs.faultForm.validate((valid) => {
          if (valid) {
            this.updateFault();
          }
        });
      },

      /**
       * 创建和更新故障
       */
      async updateFault() {
        let id = this.$route.query.id;
        this.faultInfo["parentCategory.id"] = id;
        delete this.faultInfo.parentCategory;
        let responseData = null;
        // this.faultInfo['parentCategory.id'] = this.parentCategoryId;
        if (this.isEdit) {
          responseData = await updateFaultCategory(this.faultInfo);
        } else {
          responseData = await createFaultCategory(this.faultInfo);
        }
        if (responseData && responseData.errorCode === 0 && responseData.data) {
          this.faultVisible = false;
          if (!this.isEdit) {
            this.faultList.push(responseData.data);
            this.total++;
            if (this.total % this.PAGE_SIZE === 1) {
              this.pager.page++;
            }
          } else {
            this.faultList.splice(this.choosedRowIndex, 1, responseData.data);
          }
          Notification.success({
            title: "成功",
            message: this.isEdit ? "修改成功" : "新增成功",
          });
          this.queryFault();
        } else {
          Notification.error({
            title: "错误",
            message: responseData.errorMessage,
          });
        }
      },

      /**
       *修改故障信息
       */
      handleEdit(index, row) {
        this.choosedRowIndex = index;
        this.faultInfo = Object.assign({}, row);
        this.faultInfo["parentCategory.id"] = row.parentCategory.id;
        // this.faultInfo['parentCategory.name'] = row.parentCategory.name;
        this.isEdit = true;
        this.faultVisible = true;
      },

      /**
       *  翻页
       */
      handleCurrentChange(page) {
        //        this.pager.page = page;
        //        this.queryUsers();
        if (this.loaded) {
          this.pager.page = page;
          window.router.replace({ path: "info", query: { page: page } });
          this.queryFault();
        }
      },
      /**
       * 设置分页的size
       */
      handleSizeChange(val) {
        this.pager.size = val;
        this.pager.page = 1;
        this.queryFault();
      },

      /**
       * checkbox勾选的变动的回调
       */
      selsChange: function(sels) {
        this.sels = sels;
      },

      /**
       * 单条故障的删除
       */
      handleDel: function(index, row) {
        this.$confirm("确认删除该记录吗?", "提示", {
          type: "warning",
        })
          .then(() => {
            this.listLoading = true;
            removefaultCategory(row.id).then((res) => {
              this.listLoading = false;
              if (res.errorCode === 0) {
                Notification.success({
                  title: "成功",
                  message: "删除成功",
                });
                this.queryFault();
              }
            });
          })
          .catch(() => {});
      },

      /**
       * 批量删除
       */
      batchRemove() {
        const ids = this.sels.map((item) => item.id).toString();
        this.$confirm("确认删除选中记录吗？", "提示", {
          type: "warning",
        })
          .then(() => {
            this.listLoading = true;
            removefaultCategory(ids).then((res) => {
              this.listLoading = false;
              if (res.errorCode === 0) {
                Notification.success({
                  title: "成功",
                  message: "批量删除成功",
                });
                this.queryFault();
              }
            });
          })
          .catch(() => {});
      },

      /**
       * 获取所有的一级故障
       */
      async queryFault() {
        this.listLoading = true;
        const responseData = await getAllFaultCategoryByParentId(
          this.parentId,
          this.filters.name,
          this.pager
        );
        if (responseData.errorCode === 0) {
          this.faultList =
            (responseData && responseData.data && responseData.data.content) ||
            [];
          this.total = responseData.data.totalElements;
        }
        this.loaded = true;
        this.listLoading = false;
      },
    },

    async created() {
      this.rootName = this.$route.query.name;
      if (this.$route.query.page) {
        this.pager.page = +this.$route.query.page;
      }
      this.parentId = this.$route.query.id;
      this.queryFault();
      this.queryFaultRoot();
      this.checkEditPermission();
    },

    watch: {
      faultVisible(newVal, oldVal) {
        if (this.isEdit === false) {
          this.faultInfo = {
            name: "",
            isRoot: false,
            remarks: "",
          };
        }
        if (newVal !== oldVal) {
          if (newVal === false) {
            // this.$refs.faultForm.resetFields();
            this.isEdit = false;
          }
        }
      },
    },
  };
</script>
